import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { subtitle, title, textContainer } from "../styles"
import clsx from "clsx"
import Seo from "../components/seo"

// markup
const DetailMbPage = (props) => {
  return (
    <div>
      <Seo /> 
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-3 md:gap-8">

        <div className="max-w-screen-lg mt-12 text-center col-span-2">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/mb-mockup01.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-sm mt-12">
          <h1 className={ title }>
            Product Design - MyBroker
          </h1>
          <div className={ clsx(textContainer, "text-justify") }>
            <p className="mb-4">Portima is a leading software provider for broker and insurance companies in Belgium. Portima’s mission is to help the brokers become a person of trust for their clients in a digital era.</p>
            <p className="mb-4">MyBroker is a client space offered to Portima’s brokers and designed to ease collaboration with their clients. The interface is built to allow brokers to ensure a qualitative and professional service to their clients.</p>
            <p>The goal of this project was to rethink the whole flow of the interface in order to fit any of the broker's client needs, allowing them to have a clear overview of their insurance contracts and files but also giving them the opportunity to report a claim and sign documents through the app, from anywhere. The idea was to enable the brokers to be able to support their clients 24/7 with an online client space.</p>
          </div>

          <div className={ textContainer }>
            <h2 className = { subtitle }> Client </h2>
            <p className="mb-4">Portima</p>
            <h2 className = { subtitle }> Type </h2>
            <p className="mb-4">Product Design</p>
            <h2 className = { subtitle }> Year </h2>
            <p className="mb-4">2020</p>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/mb-01.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3 ">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/mb-02.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/mb-03.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/mb-04.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/mb-05.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

      </div>
      <Footer/>
    </div>
     
  
  )
}


export default DetailMbPage